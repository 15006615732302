// eslint-disable-next-line import/no-anonymous-default-export
export default {
    account: {
        title: 'Mein Konto',
        confirmDeleteAccount: 'Möchtest du diese Informationen wirklich löschen?',
        profile: 'Meine Daten',
        addresses: 'Adressen',
        wishlists: 'Wunschzettel',
        vouchers: 'Gutscheincodes',
        logout: 'Abmelden',
        new_wishlist: 'Neuer Wunschzettel',
        greet: 'Hallo {name}!',
        orders: 'Meine Bestellungen',
        returns: 'Rücksendungen',
        userInfo: 'Benutzerdaten',
        help: 'Hilfe',
        newHere: 'Ich bin neu hier',
        freeAccount: 'Kostenloses Benutzerkonto',
        save: 'Speichern',
        edit: 'Bearbeiten',
        delete: 'Löschen',
        choose: 'Auswählen',
        change: 'Ändern',
        show: 'Anzeigen',
        cancel: 'Abbrechen',
        more: 'Weiter',
        viewMyProfile: 'Mein Profil ansehen',
        viewMyOrders: 'Meine Bestellungen ansehen',
        signUp: 'Melde dich jetzt an',
        requestResetPassword: 'Wiederherstellungslink anfordern',
        welcome: 'Willkommen ',
        placedOrders: 'Bestellungen',
        orders2: 'Bestellungen',
        latestDetails: 'Hier kannst du deine letzten Bestellungen einsehen und deine Kontodaten ändern.',
        accountDetails: 'Adressen',
        accountProfile: 'Mein Konto',
        changePassword: 'Passwort ändern',
        inOrder: 'Um ',
        protect: 'dein Konto zu schützen, ',
        passwordContain: 'Dein Passwort muss enthalten',
        characters: 'Mindestens 6 Zeichen',
        upperCase: 'Ein Großbuchstabe',
        number: 'Eine Zahl',
        specialCharacter: 'Ein Sonderzeichen (! # % +)',
        repeatEmail: 'E-Mail-Adresse wiederholen',
        editDetails: 'Details bearbeiten',
        order: 'Bestellung',
        trackingNumber: 'Tracking-Nummer: ',
        quantity: 'Menge: ',
        viewOrder: 'Bestellung ansehen',
        noOrder: 'Bestellung kann nicht angezeigt werden',
        totalAmountOrder: 'Gesamtbetrag: ',
        form: {
            emailLabel: 'Deine E-Mail Adresse',
            passwordLabel: 'Passwort*',
            minimumLength: 'Muss mindestens 6 Zeichen lang sein',
            dataProtection: 'Ich möchte mich für künftige Bestellungen registrieren und bitte um Aufnahme meiner Daten in eure Kundendatenbank. Diese Einwilligung kann ich jederzeit mit Wirkung für die Zukunft widerrufen. <a href="/datenschutz">Datenschutzerklärung</a>',
            dataEmail: 'Wir nutzen deine E-Mail-Adresse neben der Vertragsabwicklung, um dich per E-Mail über eigene ähnliche Waren/Dienstleistungen zu informieren. Du kannst dem Versand der E-Mails jederzeit <a href="https://www.sport2000.de/datenschutz#8f0ac1b5-b681-4066-a8d9-5d695b466d3b" target="_blank" class="underline">widersprechen</a>, ohne dass dir hierfür andere als die Übermittlungskosten nach den Basistarifen entstehen. <a href="/datenschutz">Datenschutzerklärung</a>',
        },
        message: {
            registered: 'Anmeldung erfolgreich – wir haben dir eine E-Mail geschickt, mit der du deinen Account aktivieren kannst.',
            logout: 'Erfolgreich abgemeldet',
            addressNew: 'Adresse hinzugefügt',
            addressUpdated: 'Adresse aktualisiert',
            addressRemoved: 'Adresse gelöscht',
            billingDefault: 'Standardversandadresse',
            shippingDefault: 'Neue Standard-Lieferadresse gesetzt',
            update: 'Account-Daten aktualisiert',
            reset: 'Falls die E-Mail Adresse korrekt ist, wurde die Reset E-Mail versendet ',
            resetFail: 'Wir konnten diese E-Mail-Adresse nicht finden',
            passwordUpdate: 'Passwort aktualisiert',
            wishlist: 'Wunschzettel {wishlist} wurde angelegt',
            wishlistAdd: 'Produkt zum Merkzettel hinzugefügt',
            wishlistRemove: 'Produkt vom Merkzettel entfernt',
            productAdd: 'Produkt zum Warenkorb hinzugefügt',
            confirmSuccess: 'Account wurde bestätigt',
            confirmError: 'Die Account-Bestätigung ist entweder fehlgeschlagen oder der Account wurde bereits bestätigt.',
            cartAdd: 'Produkt in den Warenkorb gelegt',
            cartRemove: 'Produkt aus dem Warenkorb entfernt',
            accountConfirmSuccess: 'Danke für die Bestätigung deiner Registrierung, du kannst dich jetzt einloggen',
            registeredFailed: 'Die Anmeldung ist fehlgeschlagen ',
        },
        address: {
            newAddress: 'Adresse hinzufügen',
            residential: 'Wohnanschrift',
            standardBilling: 'Standard-Rechnungsadresse',
            standardDelivery: 'Standard-Lieferadresse',
            standardShipping: 'Neue Standard-Lieferadresse gesetzt',
            removeAddress: 'Adresse entfernen',
            editAddress: 'Adresse bearbeiten',
            street: 'Straße mit Nr.',
            zip: 'PLZ',
            location: 'Ort',
            hint: 'Hinweis zur Adresse',
            land: 'Land',
            delivery: 'Lieferadresse',
            billing: 'Rechnungsadresse',
            corresponds: 'Rechnungsadresse entspricht Lieferanschrift',
            invoiceAddress: 'Rechnungsadresse:',
            addressEmpty: 'Dein Adressbuch ist leer',
        },
        eMailChange: {
            text: 'Wir stellen sicher, dass alle E-Mails von Boost an deine neue E-Mail-Adresse geschickt werden.',
            current: 'Aktuelle E-Mail-Adresse:',
            example: 'max.mustermann@gmail.com',
            newEmail: 'Neue E-Mail-Adresse',
            confirmNew: 'Neue E-Mail-Adresse bestätigen',
        },
        login: {
            emailAddress: 'E-Mail-Adresse',
            password: 'Passwort',
            login: 'Login',
            loginWithGoogle: 'Weiter mit Google',
            loginWithFacebook: 'Weiter mit Facebook',
            welcomeBack: 'Willkommen zurück',
            newToSport2000: 'Ich bin neu hier',
            forgotPassword: 'Passwort vergessen?',
            forgotPasswordHeadline: 'Passwort zurücksetzen',
            revealPassword: 'Passwort anzeigen',
            cartContext: {
                headline: 'Zur Kasse',
                LinkBackToCart: 'Zurück zum Warenkorb',
            },
        },
        register: {
            alreadyRegistered: 'Ich habe ein Konto',
            joinSport2000: 'Jetzt registrieren',
            register: 'Registrieren',
            joinUs: 'Jetzt Anmelden',
            genderMale: 'Herr',
            genderFemale: 'Frau',
            firstName: 'Vorname*',
            lastName: 'Nachname*',
            tcAgree: 'Ja, ich stimme den AGB und den Datenschutzbestimmungen von Frontastic zu.',
            tcConfirm: 'Durch die Erstellung deines Kontos erklärst du dich mit unseren Allgemeinen Geschäftsbedingungen und Datenschutzrichtlinien einverstanden.',
            informAgree: 'Ja, ich möchte per E-Mail Newsletter über Trends, Aktionen & Gutscheine informiert werden.',
            registerNow: 'Jetzt registrieren',
            createUser: 'Benutzerkonto erstellen',
            createAccount: 'Registrieren',
            continueAsGuest: 'Als Gast weiter',
            EmailExisted: 'Email adresse wird bereits verwendet',
            ConfirmationTitle: 'Anmeldung erfolgreich!',
            ConfirmationDesc: 'Wir haben dir eine E-Mail geschickt, mit der du deinen<br>Account aktivieren kannst.',
            ConfirmationButton: 'Verstanden',
        },
        password: {
            title: 'Passwort ändern',
            change: 'Zu deiner Sicherheit kannst du das Passwort jederzeit ändern.',
            current: 'Aktuelles Passwort',
            new: 'Neues Passwort',
            confirmNew: 'Neues Passwort bestätigen',
            old: 'Altes Passwort',
            password: 'Passwort*',
            passwordConfirm: 'Passwort wiederholen*',
        },
        user: {
            name: 'Name:',
            gender: 'Geschlecht:',
        },
        resetPassword: {
            headline: 'Neues Passwort erstellen',
            newPasswordLabel: 'Neues Passwort',
            newPasswordRepeatLabel: 'Wiederholung des neuen Passworts',
            submit: 'Neues Passwort übernehmen',
        },
    },
    commercetools: {
        DiscountCodeNonApplicable: 'Der Gutscheincode {discountCode} konnte nicht gefunden werden',
        NoMatchingProductDiscountFound: 'Kein passender Gutscheincode gefunden',
        OutOfStock: 'Dieses Produkt ist leider nicht mehr vorrätig',
        PriceChanged: 'Der Preis für das Produkt hat sich geändert',
        ShippingMethodDoesNotMatchCart: 'Das Produkt kann leider nicht in dein Land verschickt werden',
        MissingTaxRateForCountry: 'Das Produkt kann leider nicht in dein Land verschickt werden',
        MatchingPriceNotFound: 'Das Produkt kann leider nicht in dein Land verschickt werden',
        InvalidCredentials: 'Login fehlgeschlagen. E-Mail-Adresse oder Passwort falsch',
        InvalidLogin: 'Login fehlgeschlagen. E-Mail-Adresse oder Passwort falsch',
        InvalidCurrentPassword: 'Das Passwort ist falsch',
        LockedAccount: 'Dieser Account ist aktuell gesperrt. Bitte wende dich an den Support',
        SearchFacetPathNotFound: 'Fehler in der Suche – bitte entferne alle Suchfilter',
        SearchExecutionFailure: 'Fehler in der Suche – bitte entferne alle Suchfilter',
        NotEnabled: 'Interner Fehler',
        ExceededStock: 'Für eines oder mehrere Produkte sind weniger Items verfügbar als von Ihnen in den Warenkorb gelegt. Ihr Warenkorb wurde entsprechend aktualisiert.',
    },
    symfony: {
        notVerified: 'Bitte aktiviere erst deinen Account über die E-Mail die wir dir gesendet haben',
        invalid: 'Login fehlgeschlagen. E-Mail-Adresse oder Passwort falsch',
    },
    header: {
        absoluteIconAltTitle: 'ABSOLUTE SPORTS',
        absoluteRunIconAltTitle: 'ABSOLUTE RUN',
        absoluteTeamsportIconAltTitle: 'ABSOLUTE TEAMSPORT',
        sport2000IconAltTitle: 'SPORT 2000',
        myAccount: 'Mein Konto',
        wishlist: 'Wunschzettel',
        storeFinder: 'SPORT 2000 Standorte',
        cart: 'Warenkorb',
        closeView: 'Schließen',
        allProduct: 'Gesamtes',
        sortimentProduct: 'sortiment',
        showAllProducts: 'Gesamtes Produkte anzeigen',
        goToBlogDetail: 'Zum Propsekt',
        moreCategory: 'mehr',
        allCategory: 'Gesamte {category}',
        allCategoryGender: 'Gesamtes {category}sortiment',
        allCategoryForAlle: 'Gesamtes sortiment',
        specialCategory: 'Alle Produkte',
        absoluteTeamsport: 'Absolute <span class="teamsport">Teamsport</span>',
        runningExperts: '<span class="running">Running</span> Experts',
        marken: 'Marken',
    },
    search: {
        searchBox: {
            inputPlaceholderMobile: 'Durchsuchen',
            iconButtonSubmit: 'SPORT 2000 durchsuchen',
            buttonSubmit: 'Suchen',
            buttonClose: 'Suche abbrechen',
            buttonCloseMobile: 'Abbrechen',
            buttonClear: 'Suche leeren',
            brandPlaceholder: 'Nach einer Marke suchen',
            dropdownCategoryHeadline: 'Alles durchsuchen',
        },
        searchHits: {
            productHeadline: 'Produkte',
            blogHeadline: 'Beiträge',
            brandHeadline: 'Marken',
            storeHeadline: 'Fachgeschäft',
            suggestHeadline: 'Vorschläge',
            buttonAllResults: 'Alle ansehen',
            empty: 'Zu Ihrer Suche konnte leider nichts gefunden werden',
            colorVariant: 'Farbe',
            colorVariants: 'Farbe',
            linkAll: 'Alle ansehen',
            categoryMarken: 'Marke',
            categorySuggest: 'Suche',
            localStorage: 'Letzte Suchanfragen',
            suggest: 'Meistgesucht',
            faqHeadline: 'Häufige Fragen',
            category: 'Kategorien',
            categoryName: 'Kategorie',
            emptyCategory: 'Es wurden keine Suchergebnisse in der Kategorie ',
            emptyCategoryLast: ' gefunden. Stattdessen wurden alle Kategorien durchsucht.',
            categoryStore: 'Fachgeschäft',
        },
        emptyResult: {
            empty: 'Deine Suche nach "[{query}]" ergab leider keine Treffer.',
        },
    },
    secondNav: {
        homepage: 'Startseite',
        new: 'Neu',
        clothing: 'Bekleidung',
        shoes: 'Schuhe',
        sport: 'Sport',
        accessoires: 'Accessoires',
        sale: 'Sale %',
    },
    mobileNav: {
        back: 'Zurück',
        locations: 'Standorte',
        search: 'Suche',
        menu: 'Menü',
        account: 'Anmelden',
        cart: 'Warenkorb',
    },
    footer: {
        contact: 'Kontakt',
        serviceHour: '24-Stunden-Service',
        writeUs: 'Schreibe uns',
        emailContact: 'hallo@frontastic.cloud',
        paymentTerms: 'Zahlungsbedingungen',
        company: 'Unternehmen',
        aboutUs: 'Über uns',
        partnerships: 'Partnerschaften',
        career: 'Karriere',
        press: 'Presse',
        service: 'Service',
        track: 'Bestellung nachverfolgen',
        faq: 'FAQ',
        delivery: 'Lieferung',
        return: 'Rücksendung',
        jobs: 'Jobs',
        tc: 'AGB',
        imprint: 'Impressum',
        privacy: 'Datenschutz',
        inclVat: '(Gesamtbetrag inkl. Mwst)',
        deliveryHours: 'Lieferung in 24 Stunden',
        invoice: 'Kauf auf Rechnung',
        freeShipping: 'Kostenloser Versand & Rückversand',
    },
    miniCart: {
        color: 'Farbe: ',
        size: 'Größe: ',
        count: 'Menge: ',
        save: 'Du sparst',
        total: 'Gesamtbetrag ',
        subTotal: '(Gesamtbetrag inkl. Mwst)',
        checkout: 'Zur Kasse',
        cart: 'Warenkorb',
        close: 'Artikel zum Warenkorb hinzugefügt:',
    },
    cart: {
        uvp: 'UVP',
        color: 'Farbe',
        size: 'Größe',
        article: 'Artikel',
        myCart: 'Warenkorb',
        shipping: 'Versand',
        paymentMethods: 'Bezahlarten',
        totalSum: 'Gesamtsumme',
        save: 'Du sparst',
        enterVouchers: 'Gutscheincodes kannst du im letzten Schritt der Bestellung eingeben.',
        checkout: 'Zur Kasse',
        subtotal: 'Zwischensumme',
        shippingCosts: 'Versandkosten',
        gratis: 'kostenlos',
        inclVat: '(Gesamtbetrag inkl. Mwst)',
        totalAmount: 'Gesamtbetrag',
        totalAmountMore: '(Gesamtbetrag inkl. Mwst)',
        discounts: 'Rabatte',
        removeDiscount: 'Rabatt entfernen',
        couponCode: 'Gutscheincode',
        discountPlaceholder: 'Dein Code',
        discountCodePlaceHolder: 'Code eingeben',
        emptyCart: {
            title: 'Mein Warenkorb',
            text: 'Dein Warenkorb ist noch leer.',
        },
        registerNow: 'Jetzt anmelden',
        remove: 'Entfernen',
        titleListPayment: 'Wir akzeptieren',
        titleListLink: 'Hilfe',
        wishList: 'Wunschzettel',
        empty: 'Dein Warenkorb ist leer.',
        available: 'Verfügbar',
        notAvailable: 'Produkt im Store nicht verfügbar',
        timeDelivery: 'Lieferung 2-4 Werktage',
        quantityExceeded: 'Für eines oder mehrere Produkte sind weniger Stück verfügbar als von dir in den Warenkorb gelegt wurden. Dein Warenkorb wurde entsprechend aktualisiert.',
        removedItemsTitle: 'Produkte sind nicht mehr verfügbar und wurden entfernt:',
        removedItemTitle: 'Produkt ist nicht mehr verfügbar und wurde entfernt:',
        reducedItemsTitle: 'Die gewünschte Anzahl der Produkte übersteigt den Lagerbestand, die Anzahl wurde entsprechend reduziert.',
        reducedItemTitle: 'Die gewünschte Anzahl des Produkts übersteigt den Lagerbestand, die Anzahl wurde entsprechend reduziert.',
        headlineNoStore: 'Versand durch Sport2000',
        headlineStore: 'Versand durch Store: ',
    },
    checkout: {
        createAccount: 'Kundenkonto',
        createAccountLabel: 'Kundenkonto erstellen',
        checkoutTitleNotLogIn: 'Als Gast zur Kasse',
        checkoutTitleLoggedIn: 'Als Kunde zur Kasse',
        shipping: 'Adresse',
        payment: 'Zahlung',
        overview: 'Bestellübersicht',
        orderConfirmed: 'Deine BestelcheckPrivacylung wurde bestätigt',
        orderConfirmation: 'Wir haben die Auftragsbestätigung und Rechnung an diese Adresse gesendet:',
        continueShopping: 'Weiter einkaufen',
        wentWrong: 'Etwas ging schief während der Zahlung',
        beenCharged: 'Keine Sorge, die Bestellung ist nur eine Testbestellung, dein Konto wird nicht belastet. Du kannst es jederzeit wieder versuchen',
        yourOrderId: 'Bestellnummer: ',
        tryAgain: 'Erneut versuchen',
        backToShop: 'Zurück zum Shop',
        backToCart: 'Zurück zum Warenkorb',
        cartOutOfStock: 'Einige Produkte sind nicht vorrätig. Zurück zum Warenkorb',
        noProduct: 'Keine Produkte. Zurück zum Warenkorb',
        nextPayment: 'Weiter',
        nextOverview: 'jetzt kostenpflichtig bestellen',
        placeOrder: 'Bestellung aufgeben',
        shippingTo: 'Lieferadresse',
        shippingAddress: 'Lieferung zur Wunschadresse',
        shippingInformation: 'Lieferadresse:',
        billingDetails: 'Rechnungsadresse',
        billingInformation: 'Rechnungsadresse:',
        billingDetailsLabel: 'Eine abweichende Lieferadresse angeben',
        paymentMethod: 'Zahlungsmethode',
        overviewPage: 'Bestellung überprüfen',
        yourOrder: 'Deine Bestellung',
        orderId: 'Bestell-ID',
        invoice: 'Rechnung',
        edit: 'Bearbeiten',
        pay: 'Weiter',
        shippingMethods: 'Versandarten',
        freeShipping: 'kostenlos',
        outOfStock: 'Einige Produkte sind nicht vorrätig',
        agbCheckbox: 'Ich bin mit den Datenschutzbestimmungen, AGB und Widerrufsbestimmungen einverstanden.*',
        editDetails: 'Weiter',
        cancelDetails: 'Abbrechen',
        discount: {
            message: 'Hast du einen Gutscheincode?',
            apply: 'Einlösen',
        },
        form: {
            firstName: 'Vorname*',
            lastName: 'Nachname*',
            address: 'Straße*',
            email: 'E-Mail*',
            phone: 'Telefon',
            addressAddition: 'Adresszusatz / Firma / c/o*',
            city: 'Ort*',
            zipCode: 'Postleitzahl*',
            country: 'Land*',
            stateOrProvince: 'Staat oder Provinz*',
            streetNumber: 'Nr.*',
            companyName: 'Adresszusatz/ Firmenname',
        },
        clickCollectHeadline: 'Click & Collect',
        informationSubscribeEmail: 'Wir nutzen deine E-Mail-Adresse auch für Produktempfehlungen, die auf deinen Einkäufen basieren. Du kannst dem Empfang der E-Mails jederzeit kostenlos <a href="https://www.sport2000.de/datenschutz#8f0ac1b5-b681-4066-a8d9-5d695b466d3b" target="_blank" class="underline">widersprechen</a>. <a target="_blank" href="https://www.sport2000.de/datenschutz" class="underline">Datenschutzerklärung</a>.',
        summaryPolicy: 'Es gelten unsere Allgemeinen Geschäftsbedingungen und die Widerrufsbelehrung. Durch Anklicken des Buttons „JETZT KOSTENPFLICHTIG BESTELLEN“ gibst du eine verbindliche Bestellung ab. Wir nutzen deine E-Mail-Adresse auch für Produktempfehlungen, die auf deinen Einkäufen basieren. DU kannst dem Empfang der E-Mails jederzeit kostenlos <a href="https://www.sport2000.de/datenschutz#8f0ac1b5-b681-4066-a8d9-5d695b466d3b" target="_blank" class="underline">widersprechen</a>. <a href="https://www.sport2000.de/datenschutz" target="_blank" class="underline">Datenschutzerklärung</a>.',
    },
    wishlist: {
        title: 'Merkzettel',
        no_items: 'Dein Merkzettel ist noch leer.',
        emptyWishlist: 'Dein Merkzettel ist leer',
        errorMessage: 'Etwas lief schief. Lade die Seite neu oder browse an einer anderen Stelle weiter.',
        keepBrowsing: 'Weiter browsen',
        product: '1 Produkt',
        products: '{count} Produkte',
        myWishlist: 'Mein Merkzettel',
        headlineWishlistDetail: 'Merkzettel',
        addToWishlist: 'Auf den Merkzettel',
        removeToWishlist: 'Vom Merkzettel entfernen',
    },
    order: {
        myOrder: 'Meine Bestellung',
        number: 'Bestellnummer:',
        date: 'Bestelldatum:',
        paymentMethod: 'Zahlungsart:',
        invoice: 'Rechnung',
        cancelItem: 'Artikel stornieren',
        paymentDetails: 'Details zur Zahlung',
        payee: 'Zahlungsempfänger:',
        iban: 'IBAN:',
        bic: 'BIC:',
        bank: 'Bank:',
        paymentReason: 'Verwendungszweck:',
        total: 'Summe',
        returnProducts: 'Produkte zurücksenden',
        lastOrder: 'Letzte Bestellung',
        viewOrder: 'Bestellung ansehen',
        selectMethod: 'Zahlungsmethode wählen',
        reviewQuestion: 'Wie gefällt dir dieses Produkt?',
        pleaseRate: 'Bitte bewerte dieses Produkt',
        yourReview: 'Was würdest du über diesen Artikel sagen?',
        deliveryFeatures: {
            title: 'Express-Lieferung:',
            message: 'Bestelle in den nächsten 6 Stunden und du erhältst deine Lieferung morgen.',
        },
        yourOrderCaps: 'DEINE BESTELLUNG',
        colorOrder: 'Farbe: ',
        sizeOrder: 'Größe: ',
        shippingInformationCaps: 'VERSANDINFORMATIONEN',
        billingInformationCaps: 'ABRECHNUNGSINFORMATIONEN',
        subtotalOrder: 'Zwischensumme:',
        shipping: 'Versand:',
        includesVAT: '(Gesamtbetrag inkl. Mwst)',
        noOrders: 'Es sind keine Bestellungen auf deinem Konto verzeichnet',
        paymentFailed: 'Die ausgewählte Zahlung ist fehlgeschlagen. Bitte wähle eine andere aus',
        orderFailed: 'Die Erstellung der Bestellung ist fehlgeschlagen. Bitte prüfe den Warenkorb ob sich z.B. ein Preis oder die Anzahl geändert hat und starte den Checkout erneut.',
    },
    product: {
        product: 'Produkte',
        description: 'Beschreibung',
        shipping: 'Versand',
        reviews: 'Bewertungen',
        reviewsWithCount: '{count} Bewertungen',
        materialOuterFabric: 'Material Oberstoff:',
        color: 'Farbe',
        colorTotal: 'Farben ansehen',
        designer: 'Designer',
        commonSize: 'Vergleichsgröße',
        viscose: 'Viskose',
        lacing: 'Schnürung',
        lining: 'Futter:',
        moreAboutThisProduct: 'Mehr zu diesem Produkt',
        cut: 'Schnitt:',
        clasp: 'Verschluss:',
        style: 'Stil:',
        itemNumber: 'Artikelnummer:',
        freeShipping: 'Kostenloser Versand',
        returnPolicy100days: '100 Tage Rückgaberecht',
        standardDelivery: {
            title: 'Standard-Lieferung:',
            message: 'kostenlos 2-4 Werktage',
        },
        delivery24hs: 'Lieferung in 24 h',
        availableForDelivery: '<b>Verfügbar</b> - Lieferung 2-4 Werktage',
        freeReturns: 'Kostenlose Rücksendung',
        quantityLabel: 'Menge',
        outOfStock: 'Produkt ist nicht verfügbar',
        sizeProduct: 'Größentabelle',
        sizeManufacture: 'Herstellergröße ausblenden',
        sizeNormal: 'Herstellergröße einblenden',
        sizeSwipe: 'Nach rechts und links scrollen, um die gesamte Tabelle zu sehen.',
    },
    productTeaser: {
        newFlag: 'NEU',
        buttonIntoCart: 'Zum Produkt',
        addToCart: 'In den Warenkorb',
        color: 'Farbe',
        colors: 'Farben',
        addToWishlist: 'Zum Wunschzettel hinzufügen',
        from: 'ab',
    },
    filters: {
        total: 'Artikel anzeigen',
        newArrivals: 'Neuheiten',
        back: 'Zurück',
        save: 'Speichern',
        reset: 'Zurücksetzen',
        pattern: 'Muster',
        color: 'Farbe',
        material: 'Material',
        allBrands: 'Alle Brands',
        brand: 'Marke',
        logos: 'Spezialisten',
        services: 'Services',
        search: 'Suche',
        filter: 'Filter',
        showResults: 'Ergebnisse anzeigen',
        size: 'Größe',
        category_names: 'Kategorie',
        sortOrder: 'Sortierung',
        sort: 'Sortieren',
        price: 'Preis',
        sustainability: 'Nachhaltigkeit',
        invalidPriceRange: 'Ungültige Preisspanne',
        filters: 'Filtern',
        clear: 'Klar',
        clearAll: 'Alles löschen',
        apply: 'Zutreffen',
        loadMore: {
            products: 'Weitere {num} Produkte laden',
            articles: '{num} weitere Beiträge laden',
        },
        loadProductLeft: 'Zeige {num} mehr',
        loadAll: 'Alle Produkte laden',
        previousLoad: 'Frühere Produkte anzeigen',
        productsFound: 'Produkte gefunden',
        sortFilter: {
            lowPrice: 'Niedriger Preis',
            highPrice: 'Hoher Preis',
        },
        resetAll: 'Filter löschen',
        productStatus: 'Du hast dir {count} von {total} Produkten angeschaut',
        all: 'Alle Filter',
        less: 'Weniger Filter',
        totalArticle: '{total} Artikel anzeigen',
        unit: '€',
        variants: {
            attributes: {
                'product-manufacturer': 'Hersteller',
                color: 'Farbe',
                att_prod_color_name_sport2000: 'Farbe',
                att_prod_color_name_mf: 'Farbe',
                size: 'Größe',
                att_prod_size_eu: 'Größe',
                att_prod_brand: 'Marke',
            },
            price: 'Preis',
        },
        att_prod_gender: 'Geschlecht',
        att_text_main_material: 'Material',
        removeAllCurrent: 'Alle löschen',
        filterStores: 'Stores filtern',
        storeCount: '{num} Stores anzeigen',
        noResultHeadline: 'Leider nichts gefunden',
        noResultDesc: 'Versuche es mit einem anderen Suchbegriff oder überprüfe die Schreibweise',
        trueValue: 'Ja',
        falseValue: 'Nein',
    },
    country: {
        DE: 'Deutschland',
        AT: 'Österreich',
        PL: 'Polen',
        HU: 'Ungarn',
        ES: 'Spanien',
        PT: 'Portugal',
        CH: 'Schweiz',
        CZ: 'Tschechien',
        SI: 'Slowenien',
        SK: 'Slowakei',
        GB: 'Vereinigtes Königreich',
        CA: 'Kanada',
        US: 'Vereinigte Staaten',
    },
    state: {
        US: {
            AL: 'Alabama',
            AK: 'Alaska',
            AZ: 'Arizona',
            AR: 'Arkansas',
            CA: 'California',
            CO: 'Colorado',
            CT: 'Connecticut',
            DE: 'Delaware',
            DC: 'District of Columbia',
            FL: 'Florida',
            GA: 'Georgia',
            HI: 'Hawaii',
            ID: 'Idaho',
            IL: 'Illinois',
            IN: 'Indiana',
            IA: 'Iowa',
            KS: 'Kansas',
            KY: 'Kentucky',
            LA: 'Louisiana',
            ME: 'Maine',
            MD: 'Maryland',
            MA: 'Massachusetts',
            MI: 'Michigan',
            MN: 'Minnesota',
            MS: 'Mississippi',
            MO: 'Missouri',
            MT: 'Montana',
            NE: 'Nebraska',
            NV: 'Nevada',
            NH: 'New Hampshire',
            NJ: 'New Jersey',
            NM: 'New Mexico',
            NY: 'New York',
            NC: 'North Carolina',
            ND: 'North Dakota',
            OH: 'Ohio',
            OK: 'Oklahoma',
            OR: 'Oregon',
            PA: 'Pennsylvania',
            RI: 'Rhode Island',
            SC: 'South Carolina',
            SD: 'South Dakota',
            TN: 'Tennessee',
            TX: 'Texas',
            UT: 'Utah',
            VT: 'Vermont',
            VA: 'Virginia',
            WA: 'Washington',
            WV: 'West Virginia',
            WI: 'Wisconsin',
            WY: 'Wyoming',
        },
        CA: {
            AB: 'Alberta',
            BC: 'British Columbia',
            MB: 'Manitoba',
            NB: 'New Brunswick',
            NL: 'Newfoundland and Labrador',
            NT: 'Northwest Territories',
            NS: 'Nova Scotia',
            NU: 'Nunavut',
            ON: 'Ontario',
            PE: 'Prince Edward Island',
            QC: 'Quebec',
            SK: 'Saskatchewan',
            YT: 'Yukon',
        },
    },
    deleteItem: 'Produkt löschen',
    joinNewsletter: 'Abonniere unseren Newsletter',
    exclusiveAccess: 'Melde dich für den exklusiven Frühzugang an',
    submit: 'Absenden',
    submitAgree: 'Mit Klick auf "Absenden" stimmst du zu, dass wir deine Daten in Übereinstimmung mit unserer Datenschutzrichtlinie verwenden und die Daten für einen bestimmten Zweck verarbeiten dürfen.',
    myEmail: 'Ja, hier ist meine E-Mail-Adresse',
    colorProduct: 'Farbe:',
    sku: 'Artikel-Nr.:',
    sizeEmpty: 'Keine Größentabelle für diesen Artikel',
    noSizeChoose: 'Bitte wähle eine Größe aus',
    quantityProduct: 'Menge:',
    inCartProduct: 'In den Warenkorb',
    back: 'Zurück',
    reviews: 'Bewertungen',
    writeReview: 'Bewertung abgeben',
    ratingQuestion: 'Dieses Produkt hat Bewertungen. Möchtest du das Produkt bewerten?',
    titleBoost: 'Boost Theme',
    profile: 'Profil',
    loveIt: 'liebe-es-alternativ',
    viewMore: 'Weiterlesen',
    validation: {
        required: 'Dieses Feld muss ausgefüllt werden',
        email: 'Bitte gib eine eine gültige E-Mail-Adresse ein',
        emailMatch: 'E-Mail-Adressen stimmen nicht überein',
        passwordMatch: 'Passwörter stimmen nicht überein',
        minLength: 'Das Passwort muss mindestens 8 Zeichen lang sein.',
        passwordPattern: 'Das Passwort muss aus mindestens 7 Klein- und Großbuchstaben und 1 Zahl bestehen.',
        postalCode: 'Die Postleitzahl muss aus 5 Nummern bestehen',
        phone: 'Zahlen von 0-9 und das Präfix "+" sind erlaubt',
        fieldRequired: '*Pflichtfelder',
    },
    slider: {
        previous: 'Zurück',
        next: 'Weiter',
    },
    detail: {
        description: 'Beschreibung',
        material: 'Material & Pflege',
        review: 'Bewertungen',
        section4: 'Details',
        section5: 'Passend zum Produkt',
        youtubeVideo: 'Video',
        overview: 'Eigenschaften',
        manufactureInformation: 'Herstellerinformationen',
        contact: 'Kontakt',
    },
    sweepstakesForm: {
        salutation: {
            label: 'Anrede*',
            option1: 'Frau',
            option2: 'Herr',
            option3: 'Keine Angabe',
        },
        address: {
            firstNameLabel: 'Vorname*',
            lastNameLabel: 'Nachname*',
            emailLabel: 'E-Mail*',
            streetLabel: 'Straße*',
            houseNumberLabel: 'Nr.*',
            zipLabel: 'PLZ*',
            cityLabel: 'Ort*',
        },
        checkbox: {
            newsletterLabel: 'Ja, ich möchte den E-Mail Newsletter erhalten und über Neuigkeiten und Gewinnspiele informiert werden.',
            tosLabel: 'Ich habe die <a href="/agb">Teilnahmebedingungen</a> und <a href="/datenschutz">Datenschutzbestimmungen</a> gelesen und erkläre mich damit einverstanden.',
        },
        submitLabel: 'Absenden',
    },
    contactForm: {
        salutation: {
            label: 'Anrede',
            option1: 'Frau',
            option2: 'Herr',
            option3: 'Keine Angabe',
        },
        address: {
            firstNameLabel: 'Vorname',
            lastNameLabel: 'Nachname',
            emailLabel: 'E-Mail*',
        },
        message: {
            subjectLabel: 'Betreff*',
            messageLabel: 'Nachricht*',
        },
        checkbox: {
            newsletterLabel: 'Ja, ich möchte den E-Mail-Newsletter erhalten und über Neuigkeiten und Gewinnspiele informiert werden.',
            tosLabel: 'Ich habe die <a href="/datenschutz">Datenschutzbestimmungen</a> gelesen und erkläre mich damit einverstanden.',
            subsribeLabel: 'Ich möchte über aktuelle Aktionen, Neuigkeiten und Gewinnspiele per E-Mail informiert werden und weiß, dass ich mich jederzeit kostenlos abmelden kann. <a href="/datenschutz">Mehr zum Datenschutz</a>.',
        },
        submitLabel: 'Absenden',
        successMsg: 'Vielen Dank für Deine Nachricht, wir werden uns in Kürze bei dir melden.',
        errorMsg: 'Leider ist ein Fehler aufgetreten, bitte versuche es erneut. Sollte der Fehler dann immer noch auftreten, wenden Sie sich bitte direkt per E-Mail an unseren Kundenservice: service@sport2000.de.',
    },
    home: {
        backToShop: 'Zurück zum shop',
    },
    productDetail: {
        badge: 'Neu',
        tax: 'inkl. MwSt. und <a href="/service/versand" class="underline">kostenlosem Versand</a>',
        colorLabel: 'Farbe:',
        sizeLabel: 'Größe:',
        added: 'Hinzugefügt',
        selectedStoreWithStockStatus: 'Bestellen im Store in deiner Nähe',
        storeSidebarTitle: 'Store wählen',
        available: 'vorrätig',
        outOfStock: 'Nicht vorrätig',
        noStore: 'Artikel nicht im Sortiment',
        in: 'in',
        changeStore: 'Store ändern',
        clickAndCollect: 'Click&Collect',
    },
    common: {
        requiredInput: '* Pflichtangaben',
    },
    blog: {
        showDetail: 'Weiterlesen',
        moreBlogHeadline: 'Weitere Blogbeiträge',
    },
    breadCrumb: {
        home: 'Home',
        back: 'Zurück',
    },
    payment: {
        creditCard: 'Kreditkarte',
        paypal: 'PayPal',
        sofort: 'Sofort.',
        klarna: 'Rechnung mit Klarna',
    },
    brandDetail: {
        headlineProductList: 'Produkte von ',
        showMore: 'Mehr einblenden',
        showLess: 'Zeige weniger',
        button: 'Weiterlesen',
    },
    mailjet: {
        headline: 'Bleib auf dem Laufenden!',
        description: 'Mit unserem Newsletter keine Trends und Angebote mehr verpassen!',
        subscribe: 'Newsletter',
        title: 'Was interessiert dich?',
        cancel: 'Abbrechen',
        submit: 'Jetzt Anmelden',
        confirmHeadline: 'Newsletter abonniert',
        confirmDescription: 'Danke, dass du unseren Newsletter abonniert hast. Bitte schau in deinem Postfach nach und bestätige die Anmeldung.',
        confirmButton: 'Schliessen',
        checkAll: 'Alles',
        checkPrivacy: 'Ich stimme dem Erhalt dieses Newsletters zu und weiß, dass ich mich jederzeit problemlos abmelden kann. <a href="/datenschutz" class="underline">Mehr zum Datenschutz</a>',
        subscribeButton: 'Anmelden',
        errorDescription: 'Entschuldigung, wir haben Probleme mit dem Server',
        errorHeadline: 'Fehler Newsletter',
    },
    flyout: {
        marketingBannerLink: 'Mehr lesen',
    },
    storeFinder: {
        searchTitle: 'Storefinder',
        filterBtn: 'Filtern',
        searchBtn: 'Suchen',
        placeHolderInput: 'PLZ, Stadt oder Straße',
        sliderDetailBtn: 'Storeseite',
        routeBtn: 'Route',
        storeDetermine: 'Als meinen Store festlegen',
        services: 'Services',
        distance: 'km',
        today: 'Heute',
        permissionLocation: 'Aufforderung zur Geolokalisierung vom Benutzer verweigert',
        onlineStore: 'Online Bestellung',
        clickAndCollect: 'Click&Collect',
    },
    store: {
        opening: 'Geöffnet',
        closeSoon: 'Schließt bald',
        closed: 'Geschlossen',
        show: 'Stores anzeigen',
        hide: 'Stores ausblenden',
    },
    storeDetail: {
        telephone: 'Tel.:',
        serviceHeadline: 'Service vor Ort',
        paymentHeadline: 'Zahlungsarten vor Ort',
        brandHeadline: 'Unsere Marken vor Ort',
        teamHeadline: 'Das Team',
        sportHeadline: 'Unsere Sportarten',
        productHeadline: 'Produkte vor Ort',
        specialLogoHeadline: 'Mitglied von',
        openingHeadline: 'Öffnungszeiten',
        serviceSeeMore: 'Alle Services einblenden',
        serviceSeeLess: 'Alle Services ausblenden',
        product: 'Produkte',
        information: 'Über den Store',
        marken: 'Marken',
        headerStoreLink: 'Zum Storefinder',
        headerStoreHeadline: 'Mein Store wählen',
        headerMyStore: 'Mein Store',
        headerButtonLink: 'Zum Storefinder',
        selectStoreOption: 'Als meinen Store festlegen',
        routeMobile: 'Route',
        routeDesktop: 'Route Anzeigen',
        telephoneMobile: 'Anrufen',
        email: 'Email',
        specialLogoLink: 'Mehr erfahren',
        headerStoreDataHeadline: 'Mein Store',
        selectStoreInformation: 'Store festlegen Information',
        showMore: 'Alle Sportarten einblenden',
        showLess: 'Weniger Sportarten einblenden',
        rating: 'Bewertungen',
        ratingHeadline: 'So bewerten Kunden den Sportladen',
        viewerDescription: 'zufriedene Kunden',
        ratingValue: '/ 5',
        ratingDescription: 'Sterne',
        summaryRating: 'Uns interessiert wie zufrieden du bist! Deshalb nutzen wir Qualitize. Direkt nach deinem Einkauf im Store vor Ort kannst du uns einfach über ein Touchpad bewerten. Dadurch sammeln wir jeden Tag direktes Feedback. So können wir unsere Leistungen ständig für dich weiterentwickeln.',
        in: 'in ',
    },
    categorySidebar: {
        dropdownTitle: 'Kategorie ändern',
        close: 'close',
        menuTitle: 'Kategorien',
    },
    confirmModal: {
        yes: 'Ok',
        cancel: 'Abbrechen',
    },
    shippingMethod: {
        changeStore: 'Store wählen',
        availableStandardShipping: 'Alle Artikel verfügbar',
        availableClickCollect: 'Alle Produkte zur Abholung verfügbar',
    },
    topBar: {
        expertArea: 'Gehe zu <b>SPORT 2000</b>',
    },
    newsletterContact: {
        headline: 'Bitte wähle deine Interessen aus',
        newsRequired: 'Bitte wähle eine der Auswahlmöglichkeiten um fortzufahren',
        sportRequired: 'Bitte wähle eine der Sportarten um fortzufahren',
    },
}
